@charset "utf-8";

//transition初期設定
@mixin animation-base($property:all, $duration:.2s, $timing:ease-out) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes band {
 0% {
   width: 0%;
 }
 100% {
  width: 110%;
 }
}
/*波*/
@keyframes wave {
  0% {
    width:  0%;
    height: 0%;
    border: 1px solid rgba(202, 202, 202, 0.8);
    box-shadow: 0 0 12px 2px inset rgba(202, 202, 202, 1);
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    width:  100%;
    height: 100%;
    border: 1px solid rgba(202, 202, 202, 0.5);
    opacity: .5;
  }
}

/* 回転アニメーション */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    height: 0;
    bottom: 140px;
    opacity: 0;
  }
  50% {
    height: 100px;
    bottom: 40px;
    opacity: 1;
  }
  100% {
    height: 0;
    bottom: 40px;
    opacity: 0;
  }
}

@keyframes btn {
  0% {
    width: 0;
    right: 50px;
  }
  50% {
    width: 50px;
    right: 0;
  }
  100% {
    width: 0;
    right: 0;
  }
}

@keyframes chikachika {
  0% {
    opacity: 1;
  }
  58% {
    opacity: 0.8;
  }
  59% {
    opacity: 1;
  }
  99% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/*----------------------------------
スクロールで画像がふわっと出てくる
----------------------------------*/
.animation--fadeup {
  transition: all .8s linear;
  transform: translateY(2%);
  opacity: 0;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}
.animation--fadedown {
  @include animation-base(all,.5s);
  transition-delay: .2s;
  opacity:0;
  transform:translateY(-50px);
  &.active {
    opacity:1;
    transform: translateY(0);
  }
}
.animation--fadeup {
  transition: all .8s linear;
  opacity: 0;
  &.active {
    opacity: 1;
  }
}