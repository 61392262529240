@charset "utf-8";

/*=================================
  footer
=================================*/
.footer {
  letter-spacing: .01em;
  position: relative;
  z-index: 1;
  .copy {
    font-size: 1.2rem;
    line-height: 1;
    color: #fff;
    display: block;
    text-align: center;
    background: #333;
    padding: 11px 2%;
  }
}