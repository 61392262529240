@charset "UTF-8";


.text--min, #work .text {
  font-weight: 500;
  font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

#mainCont .mainCont__wrap .eyeCatching__text {
  font-family: ro-nikkatsu-sei-kai-std, serif;
  font-weight: 400;
  font-style: normal;
  writing-mode: vertical-rl;
}

#mainCont .mainCont__wrap .eyeCatching .scroll .text {
  font-family: 'Montserrat';
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes band {
  0% {
    width: 0%;
  }
  100% {
    width: 110%;
  }
}

/*波*/
@keyframes wave {
  0% {
    width: 0%;
    height: 0%;
    border: 1px solid rgba(202, 202, 202, 0.8);
    box-shadow: 0 0 12px 2px inset #cacaca;
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(202, 202, 202, 0.5);
    opacity: .5;
  }
}

/* 回転アニメーション */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    height: 0;
    bottom: 140px;
    opacity: 0;
  }
  50% {
    height: 100px;
    bottom: 40px;
    opacity: 1;
  }
  100% {
    height: 0;
    bottom: 40px;
    opacity: 0;
  }
}

@keyframes btn {
  0% {
    width: 0;
    right: 50px;
  }
  50% {
    width: 50px;
    right: 0;
  }
  100% {
    width: 0;
    right: 0;
  }
}

@keyframes chikachika {
  0% {
    opacity: 1;
  }
  58% {
    opacity: 0.8;
  }
  59% {
    opacity: 1;
  }
  99% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/*----------------------------------
スクロールで画像がふわっと出てくる
----------------------------------*/
.animation--fadeup {
  transition: all .8s linear;
  transform: translateY(2%);
  opacity: 0;
}

.animation--fadeup.active {
  transform: translateY(0);
  opacity: 1;
}

.animation--fadedown {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-delay: .2s;
  opacity: 0;
  transform: translateY(-50px);
}

.animation--fadedown.active {
  opacity: 1;
  transform: translateY(0);
}

.animation--fadeup {
  transition: all .8s linear;
  opacity: 0;
}

.animation--fadeup.active {
  opacity: 1;
}

a:hover {
  outline: none;
}

a:active {
  outline: none;
}

a:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  a:hover {
    opacity: .8;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: .06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover {
    opacity: .8;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after {
    opacity: 1;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #ebfafa;
  }
  /*ボタン*/
  .btnWrap a::after {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .btnWrap a:hover {
    opacity: .8;
  }
}

/*=================================
  汎用クラスの定義
=================================*/
body {
  opacity: 0;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

body.load {
  opacity: 1;
}

a {
  color: #000;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.is-sp-only {
  display: none !important;
}

.is-txt-center {
  text-align: center;
}

.is-txt-right {
  text-align: right;
}

.notes {
  font-size: 1.2rem;
  display: block;
}

.cf:after {
  content: " ";
  display: block;
  clear: both;
}

.widelink__cover img {
  width: 100%;
}

/*wrap*/
.innerWrap {
  max-width: 840px;
  margin: 0 auto;
}

/*テキスト*/
.text--min {
  font-size: 1.5rem;
  font-weight: 700;
}

/*タイトル*/
.ttl--topPage--lg {
  font-size: 4rem;
  line-height: 1.26315;
  font-weight: 700;
  color: #333;
  font-family: 'Montserrat',"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","游ゴシック Medium",YuGothic,YuGothicM,"メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 36px;
  position: relative;
}

.ttl--topPage--lg span {
  display: block;
  font-size: 1.7rem;
}

.ttl--topPage--lg::before {
  content: "";
  width: 59px;
  height: 10px;
  display: inline-block;
  background: url(/module/img/deco_ttl_lg.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ttl--topPage--lg.rl {
  writing-mode: vertical-rl;
  font-size: 3rem;
  margin: 0 auto 80px;
}

.ttl--topPage--lg.rl span {
  font-size: 1.5rem;
}

.ttl--topPage--md {
  font-size: 3.4rem;
  color: #fff;
  padding: 100px 0 50px 80px;
  margin: 0 0 60px;
  position: relative;
  left: calc((100% - 840px) / 2);
}

.ttl--topPage--md::before {
  content: "";
  width: 41px;
  height: 8px;
  display: inline-block;
  background: url(/module/img/deco_ttl_md.png) no-repeat center center;
  background-size: contain;
  margin: 0 15px 7px 0;
}

.ttl--topPage--md::after {
  content: "";
  width: 0;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%) skew(-20deg);
  background: #000;
  z-index: -1;
}

.ttl--topPage--md.active::after {
  animation: band 1s ease-in-out;
  animation-fill-mode: forwards;
}

.ttl--topPage--sm {
  font-size: 1.8rem;
  line-height: 1.26315;
  font-weight: 700;
  color: #333;
  font-family: 'Montserrat',"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","游ゴシック Medium",YuGothic,YuGothicM,"メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  padding: 1px 0 1px 15px;
  margin-bottom: 8px;
  position: relative;
}

.ttl--topPage--sm::after {
  content: "";
  width: 5px;
  height: 100%;
  display: inline-block;
  background: #000;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  /* =================================
    base
  ================================= */
  html, body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .innerWrap {
    width: 100%;
    padding: 0 5%;
  }
  .is-sp-only {
    display: block !important;
  }
  .is-pc-only {
    display: none !important;
  }
  .float-l, .float-r {
    float: none;
  }
  .widelink .widelink__cover {
    position: relative;
  }
  /*タイトル*/
  .ttl--topPage--lg {
    font-size: 2.8rem;
    margin-bottom: 40px;
  }
  .ttl--topPage--lg span {
    font-size: 1.5rem;
  }
  .ttl--topPage--lg.rl {
    writing-mode: vertical-rl;
    font-size: 3rem;
    margin: 0 auto 50px;
  }
  .ttl--topPage--md {
    font-size: 2rem;
    padding: 100px 0 30px 50px;
    left: 0;
  }
  .ttl--topPage--md::before {
    width: 37px;
    height: 4px;
    margin: 0 10px 3px 0;
  }
  .ttl--topPage--md::after {
    left: 28px;
  }
  .ttl--topPage--sm {
    font-size: 1.7rem;
    margin-bottom: 24px;
    padding: 1px 0 1px 12px;
  }
  .ttl--topPage--sm::after {
    width: 4px;
  }
}

/*=================================
  footer
=================================*/
.footer {
  letter-spacing: .01em;
  position: relative;
  z-index: 1;
}

.footer .copy {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  display: block;
  text-align: center;
  background: #333;
  padding: 11px 2%;
}

/* =================================
  toppage
================================= */
#mainCont {
  position: relative;
  z-index: 1;
}

#mainCont .mainCont__wrap .eyeCatching {
  padding: 8vh 5% 0;
  height: 100vh;
  background: url(/module/img/bg_mv.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}

#mainCont .mainCont__wrap .eyeCatching .logo--site {
  width: 64px;
  margin: 0 auto 26px;
  display: block;
}

#mainCont .mainCont__wrap .eyeCatching__text {
  font-size: 2rem;
  color: #161616;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8), 0px 0px 10px rgba(255, 255, 255, 0.8), 0px 0px 10px rgba(255, 255, 255, 0.8);
  line-height: 2;
  text-align: left;
  margin: 0 auto;
  position: absolute;
  top: calc(8vh + 106px);
  left: 50%;
  transform: translateX(-50%);
}

#mainCont .mainCont__wrap .eyeCatching .scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#mainCont .mainCont__wrap .eyeCatching .scroll .text {
  font-size: 1.2rem;
  color: #fff;
  animation: chikachika 1s infinite ease-in-out;
}

#mainCont .mainCont__wrap .eyeCatching .scroll::after {
  content: "";
  width: 1px;
  height: 0;
  display: inline-block;
  background: #fff;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  animation: scroll 2s infinite ease-in-out;
}

#work {
  padding: 80px 3% 40px;
  background: rgba(255, 255, 255, 0.6);
}

#work .text {
  font-size: 1.5rem;
  font-weight: 700;
}

#skill {
  padding: 40px 0;
  overflow: hidden;
}

#skill .skillWrap {
  padding: 0 3%;
  background: rgba(255, 255, 255, 0.6);
}

#skill .skillWrap .list {
  display: flex;
  flex-wrap: wrap;
}

#skill .skillWrap .list__item + .list__item::before {
  content: "/";
}

#skill .skillWrap + .skillWrap {
  margin-top: 30px;
}

#skill .skillTable {
  padding: 0 3%;
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.6);
}

#skill .skillTable table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

#skill .skillTable table th, #skill .skillTable table td {
  padding: 10px 8px;
  font-size: 1.4rem;
}

#skill .skillTable table th {
  width: 6em;
  text-align: left;
  border-bottom: 1px solid #000;
  vertical-align: text-top;
}

#skill .skillTable table td {
  border-bottom: 1px solid #c2c2c2;
}

#skill .contact {
  margin-top: 40px;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
}

#skill .contact .text {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 18px;
}

#skill .contact .btn--black {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: .1em;
  color: #fff;
  background: #000;
  display: block;
  width: 80%;
  padding: 15px 0;
  margin: 0 auto;
  position: relative;
}

#skill .contact .btn--black::before {
  content: "";
  width: 0;
  height: 1px;
  display: inline-block;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  animation: btn 2s infinite ease-out;
}

#profile {
  padding: 80px 3%;
  background: rgba(255, 255, 255, 0.6);
}

#profile .profile__text__in {
  -webkit-writing-mode: vertical-rl;
  -moz-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -ms-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-column-width: 17em;
  -moz-column-width: 17em;
  -ms-column-width: 17em;
  column-width: 17em;
  -moz-column-gap: 40px;
  -webkit-column-gap: 40px;
  -ms-column-gap: 40px;
  column-gap: 40px;
  margin: 0 auto;
  min-height: 1080px;
  display: inline-block;
}

#profile .profile__text strong {
  display: block;
}

#profile .profile__text p + p {
  margin-right: 20px;
}

.bgWrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.bgWrap .wave--01 .circle, .bgWrap .wave--02 .circle, .bgWrap .wave--03 .circle {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  border: 1px solid rgba(202, 202, 202, 0);
  border-radius: 50%;
}

.bgWrap .wave--01 {
  width: 100vw;
  height: 100vw;
  max-width: 700px;
  max-height: 700px;
  position: absolute;
  top: -10%;
  left: -20%;
  display: inline-block;
}

.bgWrap .wave--01 .circle {
  animation: wave 5s infinite ease-out;
  animation-fill-mode: forwards;
}

.bgWrap .wave--01 .circle:nth-child(1) {
  animation-delay: 0s;
}

.bgWrap .wave--01 .circle:nth-child(2) {
  animation-delay: .5s;
}

.bgWrap .wave--01 .circle:nth-child(3) {
  animation-delay: 1s;
}

.bgWrap .wave--01 .circle:nth-child(4) {
  animation-delay: 1.5s;
}

.bgWrap .wave--02 {
  width: 60vw;
  height: 60vw;
  max-width: 500px;
  max-height: 500px;
  position: absolute;
  bottom: -20%;
  left: -5%;
  display: inline-block;
}

.bgWrap .wave--02 .circle {
  animation: wave 5s infinite ease-out;
  animation-fill-mode: forwards;
}

.bgWrap .wave--02 .circle:nth-child(1) {
  animation-delay: 1s;
}

.bgWrap .wave--02 .circle:nth-child(2) {
  animation-delay: 1.5s;
}

.bgWrap .wave--02 .circle:nth-child(3) {
  animation-delay: 2s;
}

.bgWrap .wave--02 .circle:nth-child(4) {
  animation-delay: 2.5s;
}

.bgWrap .wave--03 {
  width: 70vw;
  height: 70vw;
  max-width: 600px;
  max-height: 600px;
  position: absolute;
  top: 30%;
  right: -20%;
  display: inline-block;
}

.bgWrap .wave--03 .circle {
  animation: wave 5s infinite ease-out;
  animation-fill-mode: forwards;
}

.bgWrap .wave--03 .circle:nth-child(1) {
  animation-delay: 2s;
}

.bgWrap .wave--03 .circle:nth-child(2) {
  animation-delay: 2.5s;
}

.bgWrap .wave--03 .circle:nth-child(3) {
  animation-delay: 3s;
}

.bgWrap .wave--03 .circle:nth-child(4) {
  animation-delay: 3.5s;
}

@media screen and (min-width: 768px) {
  /* =================================
    toppage
  ================================= */
  #mainCont .mainCont__wrap .eyeCatching .logo--site {
    width: 106px;
  }
  #mainCont .mainCont__wrap .eyeCatching__text {
    font-size: 2.4rem;
    text-shadow: none;
    top: calc(8vh + 154px);
  }
  #skill {
    padding: 80px 3%;
  }
  #skill .skillWrap {
    max-width: 840px;
    padding: 0;
    margin: 0 auto;
  }
  #skill .skillWrap + .skillWrap {
    margin-top: 50px;
  }
  #skill .skillTable {
    max-width: 840px;
    margin: 100px auto 0;
    padding: 0;
  }
  #skill .contact {
    margin-top: 80px;
  }
  #skill .contact .text {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  #skill .contact .btn--black {
    font-size: 2.4rem;
    width: 100%;
    padding: 20px 0;
    max-width: 500px;
    border: 1px solid #000;
    z-index: 1;
    overflow: hidden;
  }
  #skill .contact .btn--black::after {
    content: "";
    width: 600px;
    height: 600px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%) scale(0);
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
  }
  #skill .contact .btn--black:hover {
    color: #000;
  }
  #skill .contact .btn--black:hover::after {
    transform: translate(-50%, -50%) scale(1);
  }
  #profile {
    padding: 80px 0;
    max-width: 840px;
    margin: 0 auto;
  }
  #profile .profile__text__in {
    width: 100%;
    -webkit-column-width: 18em;
    -moz-column-width: 18em;
    -ms-column-width: 18em;
    column-width: 18em;
    -moz-column-gap: 40px;
    -webkit-column-gap: 40px;
    -ms-column-gap: 40px;
    column-gap: 40px;
    min-height: auto;
    min-height: initial;
    height: 640px;
  }
  #profile .profile__text strong {
    display: block;
  }
  #profile .profile__text p + p {
    margin-right: 40px;
  }
}

/* =================================
  width checks
================================= */
.widthChecks {
  min-height: 100vh;
}

.widthChecks__in {
  padding: 50px 1.5%;
  font-size: 1.6rem;
}

.widthChecks .jsChexk {
  margin-top: 50px;
}

.widthChecks .jsChexk .num {
  font-size: 2.2rem;
  font-weight: 700;
  color: #c20000;
}

@media screen and (min-width: 0) and (max-width: 425px) {
  .width--sp {
    display: block;
  }
  .width--tb {
    display: none;
  }
  .width--pc {
    display: none;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .width--sp {
    display: none;
  }
  .width--tb {
    display: block;
  }
  .width--pc {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  /* =================================
    width checks
  ================================= */
  .width--sp {
    display: none;
  }
  .width--tb {
    display: none;
  }
  .width--pc {
    display: block;
  }
}
