@charset "utf-8";
/* =================================
  toppage
================================= */
#mainCont {
  position: relative;
  z-index: 1;
  .mainCont__wrap {
    .eyeCatching {
      padding: 8vh 5% 0;
      height: 100vh;
      background: url(/module/img/bg_mv.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
      .logo--site {
        width: 64px;
        margin: 0 auto 26px;
        display: block;
      }
      &__text {
        font-size: 2rem;
        color: #161616;
        text-shadow: 0px 0px 10px rgba(255,255,255,.8), 0px 0px 10px rgba(255,255,255,.8), 0px 0px 10px rgba(255,255,255,.8);
        line-height: 2;
        text-align: left;
        margin: 0 auto;
        position: absolute;
        top: calc(8vh + 106px);
        left: 50%;
        transform: translateX(-50%);
        @extend %font-nikkatsu;
      }
      .scroll {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        .text {
          font-size: 1.2rem;
          @extend %font-montserrat;
          color: #fff;
          animation: chikachika 1s infinite ease-in-out;
        }
        &::after {
          content: "";
          width: 1px;
          height: 0;
          display: inline-block;
          background: #fff;
          position: absolute;
          bottom: 40px;
          left: 50%;
          transform: translateX(-50%);
          animation: scroll 2s infinite ease-in-out;
        }
      }
    }
  }
}
#work {
  padding: 80px 3% 40px;
  background: rgba(255,255,255,.6);
  .text {
    font-size: 1.5rem;
    font-weight: 700;
    @extend %font-min;
  }
}
#skill {
  padding: 40px 0;
  overflow: hidden;
  .skillWrap {
    padding: 0 3%;
    background: rgba(255,255,255,.6);
    .list {
      display: flex;
      flex-wrap: wrap;
      &__item {
        & + .list__item {
          &::before {
            content: "/";
          }
        }
      }
    }
    & + .skillWrap {
      margin-top: 30px;
    }
  }
  .skillTable {
    padding: 0 3%;
    margin-top: 40px;
    background: rgba(255,255,255,.6);
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      th,td {
        padding: 10px 8px;
        font-size: 1.4rem;
      }
      th {
        width: 6em;
        text-align: left;
        border-bottom: 1px solid #000;
        vertical-align: text-top;
      }
      td {
        border-bottom: 1px solid #c2c2c2;
      }
    }
  }
  .contact {
    margin-top: 40px;
    text-align: center;
    background: rgba(255,255,255,.6);
    .text {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 18px;
    }
    .btn--black {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: .1em;
      color: #fff;
      background: #000;
      display: block;
      width: 80%;
      padding: 15px 0;
      margin: 0 auto;
      position: relative;
      &::before {
        content: "";
        width: 0;
        height: 1px;
        display: inline-block;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        animation: btn 2s infinite ease-out;
      }
    }
  }
}
#profile {
  padding: 80px 3%;
  background: rgba(255,255,255,.6);
  .profile__text {
    &__in {
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      -ms-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      -webkit-column-width: 17em;
      -moz-column-width: 17em;
      -ms-column-width: 17em;
      column-width: 17em;
      -moz-column-gap: 40px;
      -webkit-column-gap: 40px;
      -ms-column-gap: 40px;
      column-gap: 40px;
      margin: 0 auto;
      min-height: 1080px;
      display: inline-block;
    }
    strong {
      display: block;
    }
    p + p {
      margin-right: 20px;
    }
  }
}
.bgWrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  .wave--01,.wave--02,.wave--03 {
    .circle {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border-radius: 50%;
      opacity: 0;
      border: 1px solid rgba(202, 202, 202, 0);
      border-radius: 50%;
    }
  }
  .wave--01 {
    width: 100vw;
    height: 100vw;
    max-width: 700px;
    max-height: 700px;
    position: absolute;
    top: -10%;
    left: -20%;
    display: inline-block;
    .circle {
      animation: wave 5s infinite ease-out;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: .5s;
      }
      &:nth-child(3) {
        animation-delay: 1s;
      }
      &:nth-child(4) {
        animation-delay: 1.5s;
      }
    }
  }
  .wave--02 {
    width: 60vw;
    height: 60vw;
    max-width: 500px;
    max-height: 500px;
    position: absolute;
    bottom: -20%;
    left: -5%;
    display: inline-block;
    .circle {
      animation: wave 5s infinite ease-out;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-delay: 1s;
      }
      &:nth-child(2) {
        animation-delay: 1.5s;
      }
      &:nth-child(3) {
        animation-delay: 2s;
      }
      &:nth-child(4) {
        animation-delay: 2.5s;
      }
    }
  }
  .wave--03 {
    width: 70vw;
    height: 70vw;
    max-width: 600px;
    max-height: 600px;
    position: absolute;
    top: 30%;
    right: -20%;
    display: inline-block;
    .circle {
      animation: wave 5s infinite ease-out;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        animation-delay: 2s;
      }
      &:nth-child(2) {
        animation-delay: 2.5s;
      }
      &:nth-child(3) {
        animation-delay: 3s;
      }
      &:nth-child(4) {
        animation-delay: 3.5s;
      }
    }
  }
}
@media screen and (min-width: $display-width-s) {
  /* =================================
    toppage
  ================================= */
  #mainCont {
    .mainCont__wrap {
      .eyeCatching {
        .logo--site {
          width: 106px;
        }
        &__text {
          font-size: 2.4rem;
          text-shadow: none;
          top: calc(8vh + 154px);
        }
      }
    }
  }
  #skill {
    padding: 80px 3%;
    .skillWrap {
      max-width: $base-width;
      padding: 0;
      margin: 0 auto;
      & + .skillWrap {
        margin-top: 50px;
      }
    }
    .skillTable {
      max-width: $base-width;
      margin: 100px auto 0;
      padding: 0;
    }
    .contact {
      margin-top: 80px;
      .text {
        font-size: 2rem;
        margin-bottom: 20px;
      }
      .btn--black {
        font-size: 2.4rem;
        width: 100%;
        padding: 20px 0;
        max-width: 500px;
        border: 1px solid #000;
        z-index: 1;
        overflow: hidden;
        &::after {
          content: "";
          width: 600px;
          height: 600px;
          background: rgba(255,255,255,.6);
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translate(-50%,-50%) scale(0);
          @include animation-base(all,.4s);
        }
        &:hover {
          color: #000;
          &::after {
            transform: translate(-50%,-50%) scale(1);
          }
        }
      }
    }
  }
  #profile {
    padding: 80px 0;
    max-width: $base-width;
    margin: 0 auto;
    .profile__text {
      &__in {
        width: 100%;
        -webkit-column-width: 18em;
        -moz-column-width: 18em;
        -ms-column-width: 18em;
        column-width: 18em;
        -moz-column-gap: 40px;
        -webkit-column-gap: 40px;
        -ms-column-gap: 40px;
        column-gap: 40px;
        min-height: auto;
        min-height: initial;
        height: 640px;
      }
      strong {
        display: block;
      }
      p + p {
        margin-right: 40px;
      }
    }
  }
}