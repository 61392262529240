@charset "utf-8";

/*=================================
  汎用クラスの定義
=================================*/
body {
  opacity: 0;
  @include animation-base(all,.5s,ease-in-out);
  &.load {
    opacity: 1;
  }
}
a {
  color: $color-theme;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.is-txt-right {
  text-align: right;
}
.notes {
  font-size: 1.2rem;
  display: block;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
.widelink__cover {
  img {
     width: 100%;
  }
}
/*wrap*/
.innerWrap {
  max-width: $base-width;
  margin: 0 auto;
}
/*テキスト*/
.text--min {
  font-size: 1.5rem;
  font-weight: 700;
  @extend %font-min;
}
/*タイトル*/
.ttl--topPage--lg {
  @include ttl-base(4rem);
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 36px;
  position: relative;
  span {
    display: block;
    font-size: 1.7rem;
  }
  &::before {
    content: "";
    width: 59px;
    height: 10px;
    display: inline-block;
    background: url(/module/img/deco_ttl_lg.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.rl {
    writing-mode: vertical-rl;
    font-size: 3rem;
    margin: 0 auto 80px;
    span {
      font-size: 1.5rem;
    }
  }
}
.ttl--topPage--md {
  font-size: 3.4rem;
  color: #fff;
  padding: 100px 0 50px 80px;
  margin: 0 0 60px;
  position: relative;
  left: calc((100% - #{$base-width}) / 2);
  &::before {
    content: "";
    width: 41px;
    height: 8px;
    display: inline-block;
    background: url(/module/img/deco_ttl_md.png) no-repeat center center;
    background-size: contain;
    margin: 0 15px 7px 0;
  }
  &::after {
    content: "";
    width: 0;
    height: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%) skew(-20deg);
    background: #000;
    z-index: -1;
  }
  &.active {
    &::after {
      animation: band 1s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}
.ttl--topPage--sm {
  @include ttl-base(1.8rem);
  padding: 1px 0 1px 15px;
  margin-bottom: 8px;
  position: relative;
  &::after {
    content: "";
    width: 5px;
    height: 100%;
    display: inline-block;
    background: $color-theme;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}


@media screen and (max-width: $display-width-s){
  /* =================================
    base
  ================================= */
  html,body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .innerWrap {
    width: 100%;
    padding: 0 5%;
  }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .float-l,.float-r {
    float: none;
  } 
  .widelink .widelink__cover {
    position: relative;
  }
  /*タイトル*/
  .ttl--topPage--lg {
    font-size: 2.8rem;
    margin-bottom: 40px;
    span {
      font-size: 1.5rem;
    }
    &.rl {
      writing-mode: vertical-rl;
      font-size: 3rem;
      margin: 0 auto 50px;
    }
  }
  .ttl--topPage--md {
    font-size: 2rem;
    padding: 100px 0 30px 50px;
    left: 0;
    &::before {
      width: 37px;
      height: 4px;
      margin: 0 10px 3px 0;
    }
    &::after {
      left: 28px;
    }
  }
  .ttl--topPage--sm {
    font-size: 1.7rem;
    margin-bottom: 24px;
    padding: 1px 0 1px 12px;
    &::after {
      width: 4px;
    }
  }
}