@charset "utf-8";
/* =================================
  width checks
================================= */
.widthChecks {
  min-height: 100vh;
  &__in {
    padding: 50px 1.5%;
    font-size: 1.6rem;
  }
  .jsChexk {
    margin-top: 50px;
    .num {
      font-size: 2.2rem;
      font-weight: 700;
      color: #c20000;
    }
  }
}
@media screen and (min-width: 0) and (max-width: 425px) {
  .width {
    &--sp {
      display: block;
    }
    &--tb {
      display: none;
    }
    &--pc {
      display: none;
    }
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .width {
    &--sp {
      display: none;
    }
    &--tb {
      display: block;
    }
    &--pc {
      display: none;
    }
  }
}
@media screen and (min-width: 769px) {
  /* =================================
    width checks
  ================================= */
  .width {
    &--sp {
      display: none;
    }
    &--tb {
      display: none;
    }
    &--pc {
      display: block;
    }
  }
}